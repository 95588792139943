html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.MuiDataGrid-root .total-row {
  background-color: #F3F3FA;
  font-weight: bold;
}
.MuiDataGrid-root .total-row .MuiDataGrid-cellCheckbox .MuiCheckbox-root {
  display: none;
}