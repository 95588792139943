/* 출저: https://whirl.netlify.app/ 에서 cube 선택 */

@-webkit-keyframes cube {
  0% {
    -webkit-transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }
  50% {
    -webkit-transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }
  100% {
    -webkit-transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}
@keyframes cube {
  0% {
    -webkit-transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }
  50% {
    -webkit-transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }
  100% {
    -webkit-transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}

.cube {
  -webkit-animation: cube 2s infinite ease;
  animation: cube 2s infinite ease;
  height: 40px;
  width: 40px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateY(-100px);
}
.cube div {
  background-color: #65738680;
  position: absolute;
  height: 100%;
  width: 100%;
  border: 2px solid white;
}
.cube div:nth-of-type(1) {
  -webkit-transform: translateZ(-20px) rotateY(180deg);
  transform: translateZ(-20px) rotateY(180deg);
}
.cube div:nth-of-type(2) {
  -webkit-transform: rotateY(-270deg) translateX(50%);
  transform: rotateY(-270deg) translateX(50%);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.cube div:nth-of-type(3) {
  -webkit-transform: rotateY(270deg) translateX(-50%);
  transform: rotateY(270deg) translateX(-50%);
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.cube div:nth-of-type(4) {
  -webkit-transform: rotateX(90deg) translateY(-50%);
  transform: rotateX(90deg) translateY(-50%);
  -webkit-transform-origin: top center;
  transform-origin: top center;
}
.cube div:nth-of-type(5) {
  -webkit-transform: rotateX(-90deg) translateY(50%);
  transform: rotateX(-90deg) translateY(50%);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}
.cube div:nth-of-type(6) {
  -webkit-transform: translateZ(20px);
  transform: translateZ(20px);
}
